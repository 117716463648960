$enable-gradients: true;
$enable-responsive-font-sizes: true;
@import "~bootstrap";
@import "stylesheets/partials/animation";
@import "~highcharts/css/highcharts";

:root {
  --black: black;
  --white: white;
  --mid-gray: #{$gray-200};
}

@media (prefers-color-scheme: dark) {
  :root {
    --dark: #c0c0c0; // 75% white
    --light: #292929;
    --mid-gray: #333;
    --black: #{$white};
    --white: #{$black};
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

body {
  color: var(--dark);
  background-color: var(--white);
}

.table {
  color: inherit;

  .thead-light th {
    background-color: var(--light);
    color: var(--dark);
  }
}

svg {
  fill: currentColor;
}

.highcharts-container {
  font-family: inherit;
  font-size: 14px;

  
    
      .max-0 { fill: #D9B0B7 !important; }
    
      .avg-0 { fill: #C3818C !important; }
    
      .min-0 { fill: #B46270 !important; }
    
  
    
      .max-1 { fill: #F49B90 !important; }
    
      .avg-1 { fill: #EE6352 !important; }
    
      .min-1 { fill: #D95A4B !important; }
    
  
    
      .max-2 { fill: #FBD698 !important; }
    
      .avg-2 { fill: #FAC05E !important; }
    
      .min-2 { fill: #E4AF56 !important; }
    
  
    
      .max-3 { fill: #FEF8A1 !important; }
    
      .avg-3 { fill: #FEF56C !important; }
    
      .min-3 { fill: #E7DF63 !important; }
    
  
    
      .max-4 { fill: #95DFB8 !important; }
    
      .avg-4 { fill: #59CD90 !important; }
    
      .min-4 { fill: #51BB83 !important; }
    
  
    
      .max-5 { fill: #9BFFFF !important; }
    
      .avg-5 { fill: #54EAEA !important; }
    
      .min-5 { fill: #00D3D3 !important; }
    
  

  @media (prefers-color-scheme: dark) {
    
      
        .max-0 { fill: darken(#D9B0B7, 30%) !important; }
      
        .avg-0 { fill: darken(#C3818C, 30%) !important; }
      
        .min-0 { fill: darken(#B46270, 30%) !important; }
      
    
      
        .max-1 { fill: darken(#F49B90, 30%) !important; }
      
        .avg-1 { fill: darken(#EE6352, 30%) !important; }
      
        .min-1 { fill: darken(#D95A4B, 30%) !important; }
      
    
      
        .max-2 { fill: darken(#FBD698, 30%) !important; }
      
        .avg-2 { fill: darken(#FAC05E, 30%) !important; }
      
        .min-2 { fill: darken(#E4AF56, 30%) !important; }
      
    
      
        .max-3 { fill: darken(#FEF8A1, 30%) !important; }
      
        .avg-3 { fill: darken(#FEF56C, 30%) !important; }
      
        .min-3 { fill: darken(#E7DF63, 30%) !important; }
      
    
      
        .max-4 { fill: darken(#95DFB8, 30%) !important; }
      
        .avg-4 { fill: darken(#59CD90, 30%) !important; }
      
        .min-4 { fill: darken(#51BB83, 30%) !important; }
      
    
      
        .max-5 { fill: darken(#9BFFFF, 30%) !important; }
      
        .avg-5 { fill: darken(#54EAEA, 30%) !important; }
      
        .min-5 { fill: darken(#00D3D3, 30%) !important; }
      
    
  }

  .highcharts-background {
    fill: var(--white);
  }

  .highcharts-grid-line {
    stroke: var(--mid-gray);
  }

  .highcharts-markers image.highcharts-point {
    
      &.dir-0 {
        transform: rotate(180deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-23 {
        transform: rotate(202.5deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-45 {
        transform: rotate(225deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-68 {
        transform: rotate(247.5deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-90 {
        transform: rotate(270deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-113 {
        transform: rotate(292.5deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-135 {
        transform: rotate(315deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-158 {
        transform: rotate(337.5deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-180 {
        transform: rotate(360deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-203 {
        transform: rotate(382.5deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-225 {
        transform: rotate(405deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-248 {
        transform: rotate(427.5deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-270 {
        transform: rotate(450deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-293 {
        transform: rotate(472.5deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-315 {
        transform: rotate(495deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
      &.dir-338 {
        transform: rotate(517.5deg) translate(-12px,-12px);
        transform-box: fill-box;
      }
    
  }

  .highcharts-plot-band {
    fill: var(--light);
    fill-opacity: 1;
  }

  .highcharts-series path.highcharts-point {
    stroke: var(--white);
  }

  .highcharts-tooltip-box {
    fill: var(--light);
  }

  .highcharts-axis-labels, .highcharts-legend-item > text, .highcharts-tooltip text {
    fill: var(--dark) !important;
  }

  .highcharts-color-0 {
    fill: #D1DCE4;
    stroke: none;
  }

  .highcharts-color-1 {
    fill: #9EAAB3;
    stroke: none;
  }

  .highcharts-color-2 {
    fill: #6A7177;
    stroke: none;
  }
}

@mixin padding-safe($direction) {
  @supports (padding-#{$direction}: env(safe-area-inset-#{$direction})) {
    // m#{a}x is a hack to get SASS to use CSS max() instead of its built-in version
    padding-#{$direction}: m#{a}x(calc(env(safe-area-inset-#{$direction}) - 15px), 4px) !important
  }
}

nav {
  $nav-opacity: 0.5;
  $nav-begin-color: rgba(38, 143, 255, $nav-opacity);
  --nav-begin-color: #{$nav-begin-color};
  $nav-end-color: rgba(0, 123, 255, $nav-opacity);
  --nav-end-color: #{$nav-end-color};
  @media (prefers-color-scheme: dark) {
    $darken-by: 10%;
    --nav-begin-color: #{darken($nav-begin-color, $darken-by)};
    --nav-end-color: #{darken($nav-end-color, $darken-by)};
  }
  background: var(--nav-end-color) linear-gradient(180deg, var(--nav-begin-color), var(--nav-end-color)) repeat-x;
}

#forecast-table {
  th {
    padding-bottom: 0;
    border-bottom: 0;
    vertical-align: baseline;

    a {
      color: inherit;
      text-decoration: none;
    }

    img {
      height: 30px;
      vertical-align: text-bottom;
    }
  }

  th, td {
    text-align: center;
  }
}

.font-weight-light {
  select {
    font-weight: inherit;
  }
}

.forecast-icon-label {
  margin-left: 10px;
  color: $headings-color;
  font-size: 28px;
  font-weight: normal;
}

.border-left {
  border-left: 1px solid #ccc !important;
}

.margin-left {
  margin-left: 10px;
}

.container-below-nav {
  margin-top: 44px;
}

.safe-padding {
  padding-left: 0;
  padding-right: 0;
}

.safe-padding-left {
  @include padding-safe(left);
}

.safe-padding-right {
  @include padding-safe(right);
}

.pl-4px {
  padding-left: 4px;
}

.spot-header {
  @extend .pl-4px;

  &:first-child {
    margin-top: 5px
  }

  a {
    color: inherit;

    &:hover {
      color: var(--black);
      text-decoration: none;
    }
  }

  .icon {
    margin-left: 12px;
    
    img {
      height: 22px;
      vertical-align: baseline;
    }

    &.logo img {
      height: 20px;
    }
  }
}

.spot-header, #forecast-table {
  @media (prefers-color-scheme: light) {
    a img:not(:hover) {
      opacity: 0.75;
    }
  }

  @media (prefers-color-scheme: dark) {
    img {
      filter: invert(75%);
    }

    a img:hover {
      filter: invert(100%);
    }
  }
}
